<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="questionModal"
    >   
        <CForm>
            <CRow>
              <CCol md="12">
                <CSelect
                    label="Plantilla de Pregunta"
                    id="question_id"
                    name="question_id"
                    :value.sync="question_id"
                    :options="questionOptions"
                  />
              </CCol>
            </CRow>
            <CRow>
              <CCol :md="form.type != 'content' ? 3 : 12">
                <CSelect
                    label="Tipo"
                    id="type"
                    name="type"
                    :value.sync="$v.form.type.$model"
                    :options="typeOptions"
                  />
              </CCol>
              <CCol md="9" v-if="form.type != 'content'">
                <CInput
                  label="Pregunta:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <CRow v-if="form.type != 'content'">
              <CCol md="9">
                <CInput
                  label="Descripción:"
                  :lazy="false"
                  :value.sync="$v.form.description.$model"
                  placeholder=""
                />
              </CCol>
              <CCol md="3">
                <CSelect
                    label="Requerido"
                    id="required"
                    name="required"
                    :value.sync="$v.form.required.$model"
                    :options="[{label: 'SI', value: '1'}, {label: 'NO', value: '0'}]"
                  />
              </CCol>
            </CRow>
            <CRow v-if="form.type == 'options'">
              <CCol md="12">
                <CInput
                  label="Opciones (separadas por comas):"
                  :lazy="false"
                  :value.sync="$v.form.options.$model"
                  placeholder=""
                />
              </CCol>
            </CRow>
            <CRow v-if="form.type == 'content'">
              <CCol md="12">
                <div style="padding-bottom: 10px;">Contenido:<br /></div>
                <vue-editor v-model="form.content" :editor-toolbar="customToolbar" />
                <br />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import ws from '../../../services/questions';

export default {
  name: 'DynamicQuestionModal',
  props: {
    permissions: Array
  },
  data () {
    return {
      questionModal: false,
      title: "Nueva Pregunta",
      color: "info",
      isEdit: false,
      form: {
          id: 0,
          travel_id: 0,
          travel_form_id: 0,
          question_id: 0,
          code: '',
          name: '',
          type: 'text',
          description: '',
          options: '',
          required: '1',
          special: 0
      },
      submitted: false,
      fields: [
        {key: 'name', label: 'Pregunta'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      typeOptions: [{label: "Texto", value: "text"}, {label: "Numérico", value: "number"}, {label: "Opciones", value: "options"}, {label: "Fecha", value: "date"}, {label: "Archivo", value: "file"}, {label: "Contenido", value: "content"}],
      special: 0,
      question_id: 0,
      questions: [],
      questionOptions: [],
      is_main: 1,
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"] // remove formatting button
      ],
    }
  },
  mounted: async function() {
    this.form = {
          id: 0,
          travel_id: 0,
          question_id: 0,
          code: '',
          name: '',
          type: 'text',
          description: '',
          content: '',
          options: '',
          required: '1',
          special: 0
      }
  },
  methods: {
    async storeModal (travel, is_main, travel_form_id) {
      this.questionModal = true;
      this.color = "info";
      this.title = 'Pregunta Dinámica';
      this.isEdit = false;
      this.submitted = false;
      this.special = 1;

      this.is_main = is_main;

      if(this.is_main){
        let response = await ws.getSpecialAvalilables(travel.id);

          if(response.type == "success"){
            this.questions = response.data;

            this.questionOptions = await this.$parseSelectOptions(this.questions, "id", "question");
          }
      }
      else{
        this.special = 1;
        this.title = 'Pregunta Dinámica';

        let response = await ws.getSpecialAvalilables(0);

        if(response.type == "success"){
          this.questions = response.data;

          this.questionOptions = await this.$parseSelectOptions(this.questions, "id", "question");
        }
      }

      this.question_id = this.questionOptions.length > 0 ? this.questionOptions[0].value+"" : 0;

      this.form = {
          id: 0,
          travel_id: travel.id,
          travel_form_id: travel_form_id,
          question_id: 0,
          code: '',
          name: '',
          type: 'text',
          description: '',
          options: '',
          required: '1',
          special: '1'
      }
    },
    updateModal (item, travel_form_id) {
      this.questionModal = true;
      this.color = "warning";
      this.title = 'Editar Pregunta';
      this.isEdit = true;
      this.submitted = false;

      this.special = 1;

      this.form = {
          id: item.id,
          travel_id: item.travel_id,
          travel_form_id: travel_form_id,
          code: item.code,
          name: item.question,
          type: item.type,
          description: item.description,
          options: item.options,
          required: item.required+"",
          special: 1
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.questionModal = false;

      this.submitted = false
      this.$v.$reset()
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      travel_id: { },
      question_id: { },
      name: {},
      type: { },
      description: { },
      options: { },
      required: { }
    }
  },
  watch: {
    'question_id': {
      handler: async function() {
        let response = await ws.getQuestion(this.question_id);

        if(response.type == "success"){
          let data = response.data;

          this.form = {
            id: 0,
            travel_id: this.form.travel_id,
            question_id: data.id+"",
            code: data.code,
            name: data.question,
            type: data.type,
            description: data.description,
            options: data.options,
            required: data.is_required,
            special: this.special
          }
        }
      },
      deep: true
    }
  }
}
</script>