<template>
    <CModal :title="title" :color="!is_waiting ? 'danger' : 'success'" :show.sync="deleteModal">   
        <p style="font-weight: bold;">¿Estás seguro que deseas {{!is_waiting ? 'poner en lista de espera' : 'reactivar'}} el alumno seleccionado?</p>
        <br>
        El alumno <b>{{name.toUpperCase()}}</b> será {{!is_waiting ? 'puesto en lista de espera del' : 'reactivado en el'}} viaje.
        <br /><br />
        Observaciones:
        <CTextarea v-model="observations" style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);"
          rows="3"
          text="Must be 8-20 words long."
        ></CTextarea>
        <template #header>
          <h5 class="modal-title">{{title}}</h5>
          <CButtonClose @click="deleteModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="deleteModal = false" color="secondary">Cancelar</CButton>
          <CButton v-if="!is_waiting" @click="waiting" color="danger">Poner en lista de espera</CButton>
          <CButton v-if="is_waiting" @click="active" color="danger">Reactivar</CButton>
        </template>
    </CModal>
</template>

<script>
import store from '../../../store'

export default {
  name: 'WaitingModal',
  props: {

  },
  data () {
    return {
      deleteModal: false,
      title: "",
      id: 0,
      name: 'Registro',
      observations: '',
      is_waiting: 0
    }
  },
  methods: {
    show (title, id, name, observations, is_waiting) {
      this.deleteModal = true;
      this.title = title;
      this.id = id;
      this.name = name;
      this.observations = observations;
      this.is_waiting = is_waiting;

      this.loaded();
    },
    waiting () {
      this.deleteModal = false;
      this.$emit("waiting", this.id, this.observations);
    },
    active () {
      this.deleteModal = false;
      this.$emit("active", this.id, this.observations);
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>