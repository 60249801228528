<template>
  <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="divideModal"
    >   
      <CRow>
        <CCol md="12">
        <CDataTable
          hover
          striped
          border
          small
          fixed
          :items="students"
          :fields="fields"
        >
          <template #name="{item}">
            <td style="text-transform: uppercase;">{{item.name}}</td>
          </template>
          <template #assign="{item}">
            <td class="text-center">
              <CSwitch           
              :id="'switch-'+item.id"                   
              class="mx-1"
              color="success"
              variant="3d"
              v-bind="labelIcon"
              v-model="student_ids"
              name="radio"
              @update:checked="checkAssign(item)"
              :value="item.id"
            />
            </td>
          </template>
        </CDataTable>         
        </CCol>
      </CRow>
    <template #footer>
      <CButton @click="closeModal" color="secondary">Cancelar</CButton>
      <CButton @click="store" :color="color">Dividir</CButton>
    </template>
  </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

import moment from 'moment';
import moment_timezone from 'moment-timezone'
import travels from '../../../services/travels';

moment.tz.setDefault('America/Mexico_city')

export default {
  name: 'DivideModal',
  components: {
  },
  props: {
    students:[]
  },
  data () {
    return {
      divideModal: false,
      title: "Dividir Viaje",
      color: "dark",
      form: this.getEmptyForm(),
      travel_id: 0,
      student_ids:[],
      labelIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      },
      fields: [
        {key: 'name', label: 'Nombre del Alumno'},
        {key: 'assign', label: 'Asignar a nuevo viaje'},       
      ],
    }
  },
  mounted: async function() {
  },
  methods: {
    show (id) {
      this.travel_id = id;
      this.divideModal = true;
    },
    async store () {      
      this.submitted = true;

      let params = {
        "id" : this.travel_id,
        "student_ids": this.student_ids,
      }              

      let response = await travels.divide(params);

      if(response.type == "success"){
        window.location.href = "/#/travels/";
        window.location.reload();
      }

      this.closeModal();
    },
    closeModal () {
      this.divideModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.travel_id = 0;
    },
    getEmptyForm () {
      let form = {          
          students: []
      };

      return form;
    },
    checkAssign: function(element){       
      let idx = this.student_ids.findIndex(c => c == element.id);            
      if(idx != -1){                
        this.student_ids.splice(idx, 1);                
      }else{
        this.student_ids.push(element.id);
      }
    },
  },
  computed: {
  },
  mixins: [],
}
</script>