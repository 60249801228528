<template>
  <div>
    <CRow>
      <CCol sm="12">
        <span style="float: right; text-align: right;">{{travel.destination}}<br/>{{travel.dates}}<br /><br /><CButton color="info" size="sm" @click="syncDrive()" v-c-tooltip="{content: 'Sincronizar con Drive', placement: 'top'}">Sincronizar Drive</CButton><CButton color="success" size="sm" @click="externalList()" v-c-tooltip="{content: 'Documentos', placement: 'top'}" style="margin-left: 10px;">Documentos</CButton></span>
        <h1>{{travel.name}}</h1>        
        <a :href="travel.spreadsheet_link" target="_blank">{{travel.spreadsheet_link}}</a> 
        <br /><br />
        <CNav variant="tabs" role="tablist">
          <!-- <li class="nav-item" :class="tab === 'information' ? 'tab_active' : ''"><a href="#" target="_self" class="nav-link" @click="changeTab('information')"><CLink href="javascript:void(0);" :active="tab === 'information'"> Información </CLink></a></li> -->
          <li class="nav-item" :class="tab === 'students' ? 'tab_active' : ''"><a :href="'/#/travels/'+travel.id+'/show'" target="_self" class="nav-link" @click="changeTab('students')"><CLink href="javascript:void(0);" :active="tab === 'students'"> Alumnos </CLink></a></li>
          <li class="nav-item" :class="tab === 'payments' ? 'tab_active' : ''"><a :href="'/#/travels/'+travel.id+'/show'" target="_self" class="nav-link" @click="changeTab('payments')"><CLink href="javascript:void(0);" :active="tab === 'payments'"> Pagos </CLink></a></li>
          <li class="nav-item" :class="tab === 'form' ? 'tab_active' : ''"><a :href="'/#/travels/'+travel.id+'/show'" target="_self" class="nav-link" @click="changeTab('form')"><CLink href="javascript:void(0);" :active="tab === 'form'"> Formulario </CLink></a></li>
          <template v-for="(form, i) in forms">
            <li :key="'nav-nav'+i" v-if="form.is_main == 0" class="nav-item" :class="tab === form.code ? 'tab_active' : ''"><a :href="'/#/travels/'+travel.id+'/show'" target="_self" class="nav-link" @click="changeTab(form.code)"><CLink href="javascript:void(0);" :active="tab === 'form'">{{form.presentation_link}}</CLink></a></li>  
          </template>          
          <li class="nav-item" :class="'tab_active'"><a href="#" target="_self" class="nav-link" @click="addFormModal"><CLink href="javascript:void(0);" :active="tab === 'form'"> + </CLink></a></li>
        </CNav>
        <CTabs class="remove">
          <CTab role="tabpanel" aria-labelledby="form-tab" v-if="tab === 'form'">
            <QuestionsTable
              hover
              striped
              border
              small
              fixed
              caption="Preguntas del Formulario"
              :id="id"
              :travel="travel"
            />
          </CTab>
          <CTab role="tabpanel" aria-labelledby="students-tab" v-if="tab === 'students'">
            <StudentsTable
              hover
              striped
              border
              small
              fixed
              caption="Alumnos"
              :id="id"
              :travel="travel"
            />
          </CTab>
          <CTab role="tabpanel" aria-labelledby="payments-tab" v-if="tab === 'payments'">
            <PaymentsTable
              hover
              striped
              border
              small
              fixed
              caption="Pagos"
              :id="id"
              type="travel"
              :travel="travel"
            />
          </CTab>
          <template v-for="(form,i) in forms">
            <CTab  role="tabpanel" aria-labelledby="forms-tab" v-if="tab === form.code" :key="'Ctap-panel'+i">
              <DynamicFormQuestionsTable
                hover
                striped
                border
                small
                fixed
                caption="Preguntas del Formulario"
                :id="id"
                :travel="travel"
                :form="form"
                :fields="questions_fields"
              />
            </CTab>
          </template>          
        </CTabs>
      </CCol>
      <AddTravelFormModal ref="addTravelFormModal" @store="storeProcess" @update="updateProcess"></AddTravelFormModal>
    </CRow>
  </div>
</template>

<script>
import StudentsTable from '../../components/travels/students/StudentsTable.vue'
import PaymentsTable from '../../components/travels/payments/PaymentsTable.vue'
import QuestionsTable from '../../components/travels/questions/QuestionsTable.vue'
import DynamicFormQuestionsTable from '../../components/travels/questions/DynamicFormQuestionsTable.vue'
import AddTravelFormModal from '../../components/travels/AddTravelFormModal.vue'
import travels from '../../services/travels';
import store from '../../store'

export default {
  name: 'Travel',
  components: { StudentsTable, PaymentsTable, QuestionsTable, AddTravelFormModal, DynamicFormQuestionsTable },
  data: function () {
		return {
            tab: 'students',
            travel: false,
            id: 0,
            forms: [],
            questions_fields: [
              {key: 'position', label: 'No.'},
              {key: 'type', label: 'Tipo'},
              {key: 'question', label: 'Pregunta'},
              {key: 'options', label: 'Opciones'},
              {key: 'actions', label: 'Acciones'}
            ]
        }
  },
  mounted: async function() {
    this.loading();

    this.id = await this.$route.params.id;

    let response = await travels.showById(this.id);

    if(response.type == "success"){
      this.travel = response.data;
    }

    let response2 = await travels.forms(this.id);

    if(response2.type == "success"){
      this.forms = response2.data;
    }

    let travel_tab = localStorage.getItem("travel_tab");

    if(travel_tab == undefined || travel_tab == null){
      if(this.tab === false){
        this.tab = "students";
        localStorage.setItem("travel_tab", this.tab);
      }
    }
    else{
      this.tab = travel_tab;
    }

    this.changeTab(this.tab)

    this.loaded();
  },
  methods: {
    async changeTab(tab){
      this.loading();
      this.tab = tab;

      localStorage.setItem("travel_tab", this.tab);

      if(tab == "payments"){
        let response = await travels.studentsWithPayments(this.id);

        if(response.type == "success"){
          this.students = response.data;
        }
      }

      this.loaded();
    },
    addFormModal(){
      this.$refs.addTravelFormModal.storeModal(this.travel);
    },
    async storeProcess(data){
      this.loading();
      let response = await travels.addTravelForm(data);

      this.forms = false;

      if(response.type == "success"){
        let form = response.data;

        let response2 = await travels.forms(this.id);

        if(response2.type == "success"){
          let _this = this;
          setTimeout(async function(){
            _this.forms = response2.data;

            setTimeout(async function(){
              _this.changeTab(form.code)
            }, 100);
          }, 100);
        }
      }

      this.showToast(response.type, response.message);

      this.loaded();
    },
    async syncDrive(){
      this.loading();

      let response = await travels.syncDrive(this.travel.id);

      this.showToast(response.type, response.message);

      this.loaded();
    },
    async externalList(){
      this.loading();

      let url = window.location.protocol+"//"+window.location.host+"/#/student/external/"+this.travel.spreadsheet_id;

      window.open(url, '_blank');

      this.loaded();
    },
    updateProcess(data){

    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style>
.remove .nav.nav-tabs{
  display: none;
}
</style>