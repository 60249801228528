<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{title}}
        <div class="card-header-actions">
          <CButton v-if="!show_specials" color="success" size="sm" @click="showQuestions(1)">Preguntas Dinámicas</CButton>
          <CButton v-if="show_specials" color="success" size="sm" @click="showQuestions(0)">Preguntas Genéricas</CButton>
          <CButton color="info" size="sm" @click="questionModal()">Nueva Pregunta</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="questions"
        :fields="fields"
        :items-per-page="30"
        :dark="dark"
        pagination
        :tableFilter="{label: 'Buscar: ', placeholder: ' '}"
        :itemsPerPageSelect="{label: 'Preguntas por página: ', values: [10,20,30,50,100]}"
      >
        <template #position="{item}"><td class="text-center w100">{{item.position}}</td></template>
        <template #type="{item}"><td class="text-center w100">{{item.type == 'text' ? 'Texto' : (item.type == 'options' ? 'Opciones' : (item.type == 'date' ? 'Fecha' : (item.type == 'file' ? 'Archivo' : (item.type == 'number' ? 'Numérico' : 'N/A'))))}}</td></template>
        <template #question="{item}"><td class="text-left"><strong>{{item.question}} <span style="color: red;" v-if="item.required">*</span></strong><br />{{item.description}}</td></template>
        <template #options="{item}"><td class="text-left w400" style="vertical-align: middle;"><span v-if="item.optionsLabels"><ul style="margin: 0px;"><li v-for="option in item.optionsLabels">{{option}}</li></ul></span></td></template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton color="warning" size="sm" @click="updateQuestionModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteQuestionModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <QuestionModal ref="questionFormModal" @store="storeProcess" @update="updateProcess"></QuestionModal>
    <DeleteModal ref="deleteQuestionFormModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import QuestionModal from './QuestionModal.vue'
import DeleteModal from '../../../components/global/DeleteModal.vue'

import ws from '../../../services/travels';
import store from '../../../store'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

export default {
  name: 'QuestionsTable',
  components: { QuestionModal, DeleteModal },
  props: {
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'position', label: 'No.'},
          {key: 'type', label: 'Tipo'},
          {key: 'question', label: 'Pregunta'},
          {key: 'options', label: 'Opciones'},
          {key: 'actions', label: 'Acciones'}
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    id: 0,
    travel: {},
  },
  data() {
    return {
      toasts: [],
      title: 'Preguntas Genéricas',
      show_specials: false,
      questions: [],
      special_questions: [],
      students: [],
      payments: [],
      ps: []
    }
  },
  mounted: async function() {
    let _this = this;

    setTimeout(async function(){
      let response1 = await ws.questions(_this.id);

      if(response1.type == "success"){
          _this.questions = response1.data;
      }
    }, 100);
  },
  methods: {
    async showQuestions (special) {
      if(special == 0){
        let response1 = await ws.questions(this.id);

        if(response1.type == "success"){
            this.questions = response1.data;

            this.show_specials = false;
            this.title = 'Preguntas Genéricas';
        }
      }
      else{
        let response2 = await ws.specialQuestions(this.id);

        if(response2.type == "success"){
            this.questions = response2.data;

            this.show_specials = true;
            this.title = 'Preguntas Dinámicas';
        }
      }
    },
    questionModal () {
      this.$refs.questionFormModal.storeModal(this.travel, 1, this.show_specials);
    },
    updateQuestionModal (item) {
      this.$refs.questionFormModal.updateModal(this.show_specials, item);
    },
    deleteQuestionModal (item) {
      this.$refs.deleteQuestionFormModal.show("Eliminar Pregunta", item.id, item.name);
    },
    async storeProcess(data) {
      let response = await ws.storeQuestion(data);
      
      if(response.type == "success"){
        this.questions = response.data;
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.updateQuestion(data);

      if(response.type == "success"){
        this.questions = response.data;
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(data){
      this.loading();
      let response = await ws.deleteQuestion(data);

      if(response.type == "success"){
        this.questions = response.data;
      }

      this.showToast(response.type, response.message);

      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
