<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="travelsModal"
    >   
        <CForm id="add_travel_form" method="POST" enctype="multipart/form-data">
          <CRow>
            <CCol md="3">
              <CInput
                label="Identificador:"
                id="code"
                name="code"
                :lazy="false"
                :value.sync="$v.travel_form.code.$model"
                :isValid="checkIfValid('code')"
                placeholder=""
                autocomplete="given-code"
                invalidFeedback="Este campo es requerido y debe tener mínimo a 3 caracteres y máximo 25 caracteres."
              />
            </CCol>
            <CCol md="9">
              <CInput
                label="Título:"
                id="name"
                name="name"
                :lazy="false"
                :value.sync="$v.travel_form.name.$model"
                :isValid="checkIfValid('name')"
                placeholder=""
                autocomplete="given-name"
                invalidFeedback="Este campo es requerido y debe tener mínimo a 3 caracteres."
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div style="padding-bottom: 10px;">Mensaje de Bienvenida:<br /></div>
              <vue-editor v-model="travel_form.welcome" :editor-toolbar="customToolbar" />
              <br />
            </CCol>
          </CRow>
       </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">{{travel_form.id > 0 ? 'Guardar' : 'Registrar'}}</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'
import { VueEditor } from "vue2-editor";
import travels from '../../services/travels';
import schools from '../../services/schools';
import bank_accounts from '../../services/bank_accounts';

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss")); 
let today2 = new moment(today.format("YYYY-MM-DD HH:m:ss")).subtract(1, 'month');

export default {
  name: 'AddTravelFormModal',
  components: {
    'v-date-picker': DatePicker,
    VueEditor 
  },
  props: {

  },
  data () {
    return {
      travelsModal: false,
      title: "Nuevo Viaje",
      color: "info",
      isEdit: false,
      travel_form: this.getEmptyForm(),
      submitted: false,
      content: "<h1>Some initial content</h1>",
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"] // remove formatting button
      ],
      travel_id: 0
    }
  },
  mounted: async function() {

  },
  methods: {
    storeModal (travel) {
      this.$v.$reset()

      this.travelsModal = true;
      this.color = "info";
      this.title = 'Nuevo Formulario';
      this.isEdit = false;
      this.submitted = false;

      this.travel_id = travel.id;

      this.travel_form = {
          id: 0,
          code: '',
          name: ''
      }
    },
    updateModal (item) {
      this.travelsModal = true;
      this.color = "warning";
      this.title = 'Editar Viaje';
      this.isEdit = true;
      this.submitted = false;

      let start_date = item.start_date != null ? moment(item.start_date, "YYYY-MM-DD") : moment().add(1, 'months');
      let end_date = item.end_date != null ? moment(item.end_date, "YYYY-MM-DD") : moment().add(1, 'months').add(10, 'days');

      this.form = {
          id: item.id,
          name: item.name,
          school_id: item.school_id,
          type: item.type,
          destination: item.destination,
          contact_name: item.contact_name,
          contact_phone: item.contact_phone,
          image: '',
          start_date: start_date.toDate(),
          end_date: end_date.toDate(),
          welcome_message: item.welcome,
          presentation_link: item.presentation,
          itinerary_message1: item.itinerary1,
          itinerary_message2: item.itinerary2,
          insurance_link: item.insurance,
          payments: [
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              date: false
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              date: false
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              date: false
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              date: false
            },
            {
              bank_account_base: "0",
              bank_account_id: "0",
              description: '',
              amount: '',
              date: false
            }
          ]
      };

      let i = 0;
      item.payments.forEach(payment => {
        if(this.travel_form.payments[i] != undefined){
          this.travel_form.payments[i].id = payment.id;
          this.travel_form.payments[i].description = payment.name;
          this.travel_form.payments[i].amount = payment.amount;
          this.travel_form.payments[i].bank_account_id = payment.bank_account_id != undefined ? payment.bank_account_id+"" : "0";
          this.travel_form.payments[i].bank_account_base = payment.bank_account_id != undefined ? payment.bank_account_id+"" : "0";

          let deadline = moment(payment.deadline, "YYYY-MM-DD");

          this.travel_form.payments[i].date = deadline.toDate();
        }
        
        i++;
      });
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        const form = document.getElementById("add_travel_form");
        const formData = new FormData(form);

        formData.append("travel_id", this.travel_id);

        formData.append("welcome", this.travel_form.welcome);

        if(this.travel_form.id > 0){
          formData.append("id", this.travel_form.id);
          
          this.$emit("update", formData );
        }
        else{
          this.$emit("store", formData );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.travelsModal = false;

      this.travel_form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      let travel_form = {
          id: 0,
          code: '',
          name: ''
      };

      return travel_form;
    },
    checkIfValid (fieldName) {
      const field = this.$v.travel_form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.travel_form, null, 4) },
    isValid () { return !this.$v.travel_form.$invalid },
    isDirty () { return this.$v.travel_form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    travel_form: {
      code: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(15)
      },
      name: {
        required,
        minLength: minLength(3)
      }
    }
  },
}
</script>