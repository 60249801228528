<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> Registro de Pagos
        <div class="card-header-actions">

        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        class="payments"
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="students"
        :fields="ps"
        :items-per-page="100"
        :dark="dark"
        pagination
        :tableFilter="{label: 'Buscar: ', placeholder: ' '}"
        :itemsPerPageSelect="{label: 'Alumnos por página: ', values: [10,20,30,50,100]}"
      >
        <template #position="{item}"><td class="w75 text-center">{{item.position}}</td></template>
        <template #name="{item}"><td style="text-transform: uppercase;"><strong>{{item.name}}</strong><br /><br />{{item.customer_name}}<br />{{item.customer_phone}}</td></template>
        <template #p1="{item}"><td class="w150 text-center" style="font-size: 12px;">
          <span v-if="item.payments[0].amount <= 100 || item.payments[0].amount == 99999">N/D<br /></span>
          <span v-if="item.payments[0].amount > 100 && item.payments[0].amount != 99999">
            <span v-if="item.payments[0].enrollment_payment == 0">
              <span v-if="item.payments[0].amount != 88888 && item.payments[0].amount != 99999">
                {{travel.type != 'national' ? 'USD' : ''}} {{item.payments[0].amount | currency}} {{travel.type != 'national' ? '' : 'Pesos'}}
                <br />
              </span>
              <span v-if="item.payments[0].enrollment_payment == 0">
                <br /><CButton color="danger" size="sm" @click="paymentModal(item, item.payments[0])" v-c-tooltip="{content: 'Registrar Pago', placement: 'top'}">PAGAR</CButton>
              </span>
            </span>
            <span v-if="item.payments[0].enrollment_payment != 0">
              {{item.payments[0].enrollment_payment.date | date}}<br />
              MXN {{item.payments[0].enrollment_payment.amount | currency}}
              <br />
              <span>
                <br />
                <CButton color="info" size="sm" @click="updatePaymentModal(item, item.payments[0])" v-c-tooltip="{content: 'Editar Pago', placement: 'top'}">PAGADO</CButton>
                <CButton v-if="item.payments[0].enrollment_payment.status != 'validated'" style="margin-left: 5px;" color="warning" size="sm" @click="validatePaymentModal(item, item.payments[0])" v-c-tooltip="{content: 'Validar Pago', placement: 'top'}">
                  <CIcon name="cil-warning" style="width: 0.6rem;height: 0.6rem;font-size: 0.6rem;"/>
                </CButton>
                <CButton v-if="item.payments[0].enrollment_payment.status == 'validated'" style="margin-left: 5px;" color="success" size="sm" @click="devalidatePaymentModal(item, item.payments[0])" v-c-tooltip="{content: 'Validar Pago', placement: 'top'}">
                  <CIcon name="cil-check" style="width: 0.6rem;height: 0.6rem;font-size: 0.6rem;"/>
                </CButton>
                <br /><br />
                <CButton v-if="item.payments[0].enrollment_payment.filename != 'N/A'" style="margin-left: 5px;" color="info" size="sm" @click="downloadReceipt(item, item.payments[0])" v-c-tooltip="{content: 'Comprobante de Pago', placement: 'top'}">
                  <CIcon name="cil-file" style="width: 0.75rem;height: 0.75rem;font-size: 0.75rem;"/>
                </CButton>
                <CButton style="margin-left: 5px;" color="danger" size="sm" @click="deletePaymentModal(item, item.payments[0])" v-c-tooltip="{content: 'Eliminar Pago', placement: 'top'}">
                  <CIcon name="cil-trash" style="width: 0.75rem;height: 0.75rem;font-size: 0.75rem;"/>
                </CButton>
              </span>
            </span>
          </span>
        </td></template>
        <template #p2="{item}"><td class="w150 text-center" style="font-size: 12px;">
          <span v-if="item.payments[1].amount <= 100 || item.payments[1].amount == 99999">N/D<br /></span>
          <span v-if="item.payments[1].amount > 100 && item.payments[1].amount != 99999">
            <span v-if="item.payments[1].enrollment_payment == 0">
              <span v-if="item.payments[1].amount != 88888 && item.payments[1].amount != 99999">
                {{travel.type != 'national' ? 'USD' : ''}} {{item.payments[1].amount | currency}} {{travel.type != 'national' ? '' : 'Pesos'}}
                <br />
              </span>
              <span v-if="item.payments[1].enrollment_payment == 0">
                <br /><CButton color="danger" size="sm" @click="paymentModal(item, item.payments[1])" v-c-tooltip="{content: 'Registrar Pago', placement: 'top'}">PAGAR</CButton>
              </span>
            </span>
            <span v-if="item.payments[1].enrollment_payment != 0">
              {{item.payments[1].enrollment_payment.date | date}}<br />
              MXN {{item.payments[1].enrollment_payment.amount | currency}}
              <br />
              <span>
                <br />
                <CButton color="info" size="sm" @click="updatePaymentModal(item, item.payments[1])" v-c-tooltip="{content: 'Editar Pago', placement: 'top'}">PAGADO</CButton>
                <CButton v-if="item.payments[1].enrollment_payment.status != 'validated'" style="margin-left: 5px;" color="warning" size="sm" @click="validatePaymentModal(item, item.payments[1])" v-c-tooltip="{content: 'Validar Pago', placement: 'top'}">
                  <CIcon name="cil-warning" style="width: 0.6rem;height: 0.6rem;font-size: 0.6rem;"/>
                </CButton>
                <CButton v-if="item.payments[1].enrollment_payment.status == 'validated'" style="margin-left: 5px;" color="success" size="sm" @click="devalidatePaymentModal(item, item.payments[1])" v-c-tooltip="{content: 'Validar Pago', placement: 'top'}">
                  <CIcon name="cil-check" style="width: 0.6rem;height: 0.6rem;font-size: 0.6rem;"/>
                </CButton>
                <br /><br />
                <CButton v-if="item.payments[1].enrollment_payment.filename != 'N/A'" style="margin-left: 5px;" color="info" size="sm" @click="downloadReceipt(item, item.payments[1])" v-c-tooltip="{content: 'Comprobante de Pago', placement: 'top'}">
                  <CIcon name="cil-file" style="width: 0.75rem;height: 0.75rem;font-size: 0.75rem;"/>
                </CButton>
                <CButton style="margin-left: 5px;" color="danger" size="sm" @click="deletePaymentModal(item, item.payments[1])" v-c-tooltip="{content: 'Eliminar Pago', placement: 'top'}">
                  <CIcon name="cil-trash" style="width: 0.75rem;height: 0.75rem;font-size: 0.75rem;"/>
                </CButton>
              </span>
            </span>
          </span>
        </td></template>
        <template #p3="{item}"><td class="w150 text-center" style="font-size: 12px;">
          <span v-if="item.payments[2].amount <= 100 || item.payments[2].amount == 99999">N/D<br /></span>
          <span v-if="item.payments[2].amount > 100 && item.payments[2].amount != 99999">
            <span v-if="item.payments[2].enrollment_payment == 0">
              <span v-if="item.payments[2].amount != 88888 && item.payments[2].amount != 99999">
                {{travel.type != 'national' ? 'USD' : ''}} {{item.payments[2].amount | currency}} {{travel.type != 'national' ? '' : 'Pesos'}}
                <br />
              </span>
              <span v-if="item.payments[2].enrollment_payment == 0">
                <br /><CButton color="danger" size="sm" @click="paymentModal(item, item.payments[2])" v-c-tooltip="{content: 'Registrar Pago', placement: 'top'}">PAGAR</CButton>
              </span>
            </span>
            <span v-if="item.payments[2].enrollment_payment != 0">
              {{item.payments[2].enrollment_payment.date | date}}<br />
              MXN {{item.payments[2].enrollment_payment.amount | currency}}
              <br />
              <span>
                <br />
                <CButton color="info" size="sm" @click="updatePaymentModal(item, item.payments[2])" v-c-tooltip="{content: 'Editar Pago', placement: 'top'}">PAGADO</CButton>
                <CButton v-if="item.payments[2].enrollment_payment.status != 'validated'" style="margin-left: 5px;" color="warning" size="sm" @click="validatePaymentModal(item, item.payments[2])" v-c-tooltip="{content: 'Validar Pago', placement: 'top'}">
                  <CIcon name="cil-warning" style="width: 0.6rem;height: 0.6rem;font-size: 0.6rem;"/>
                </CButton>
                <CButton v-if="item.payments[2].enrollment_payment.status == 'validated'" style="margin-left: 5px;" color="success" size="sm" @click="devalidatePaymentModal(item, item.payments[2])" v-c-tooltip="{content: 'Validar Pago', placement: 'top'}">
                  <CIcon name="cil-check" style="width: 0.6rem;height: 0.6rem;font-size: 0.6rem;"/>
                </CButton>
                <br /><br />
                <CButton v-if="item.payments[2].enrollment_payment.filename != 'N/A'" style="margin-left: 5px;" color="info" size="sm" @click="downloadReceipt(item, item.payments[2])" v-c-tooltip="{content: 'Comprobante de Pago', placement: 'top'}">
                  <CIcon name="cil-file" style="width: 0.75rem;height: 0.75rem;font-size: 0.75rem;"/>
                </CButton>
                <CButton style="margin-left: 5px;" color="danger" size="sm" @click="deletePaymentModal(item, item.payments[2])" v-c-tooltip="{content: 'Eliminar Pago', placement: 'top'}">
                  <CIcon name="cil-trash" style="width: 0.75rem;height: 0.75rem;font-size: 0.75rem;"/>
                </CButton>
              </span>
            </span>
          </span>
        </td></template>
        <template #p4="{item}"><td class="w150 text-center" style="font-size: 12px;">
          <span v-if="item.payments[3].amount <= 100 || item.payments[3].amount == 99999">N/D<br /></span>
          <span v-if="item.payments[3].amount > 100 && item.payments[3].amount != 99999">
            <span v-if="item.payments[3].enrollment_payment == 0">
              <span v-if="item.payments[3].amount != 88888 && item.payments[3].amount != 99999">
                {{travel.type != 'national' ? 'USD' : ''}} {{item.payments[3].amount | currency}} {{travel.type != 'national' ? '' : 'Pesos'}}
                <br />
              </span>
              <span v-if="item.payments[3].enrollment_payment == 0">
                <br /><CButton color="danger" size="sm" @click="paymentModal(item, item.payments[3])" v-c-tooltip="{content: 'Registrar Pago', placement: 'top'}">PAGAR</CButton>
              </span>
            </span>
            <span v-if="item.payments[3].enrollment_payment != 0">
              {{item.payments[3].enrollment_payment.date | date}}<br />
              MXN {{item.payments[3].enrollment_payment.amount | currency}}
              <br />
              <span>
                <br />
                <CButton color="info" size="sm" @click="updatePaymentModal(item, item.payments[3])" v-c-tooltip="{content: 'Editar Pago', placement: 'top'}">PAGADO</CButton>
                <CButton v-if="item.payments[3].enrollment_payment.status != 'validated'" style="margin-left: 5px;" color="warning" size="sm" @click="validatePaymentModal(item, item.payments[3])" v-c-tooltip="{content: 'Validar Pago', placement: 'top'}">
                  <CIcon name="cil-warning" style="width: 0.6rem;height: 0.6rem;font-size: 0.6rem;"/>
                </CButton>
                <CButton v-if="item.payments[3].enrollment_payment.status == 'validated'" style="margin-left: 5px;" color="success" size="sm" @click="devalidatePaymentModal(item, item.payments[3])" v-c-tooltip="{content: 'Validar Pago', placement: 'top'}">
                  <CIcon name="cil-check" style="width: 0.6rem;height: 0.6rem;font-size: 0.6rem;"/>
                </CButton>
                <br /><br />
                <CButton v-if="item.payments[3].enrollment_payment.filename != 'N/A'" style="margin-left: 5px;" color="info" size="sm" @click="downloadReceipt(item, item.payments[3])" v-c-tooltip="{content: 'Comprobante de Pago', placement: 'top'}">
                  <CIcon name="cil-file" style="width: 0.75rem;height: 0.75rem;font-size: 0.75rem;"/>
                </CButton>
                <CButton style="margin-left: 5px;" color="danger" size="sm" @click="deletePaymentModal(item, item.payments[3])" v-c-tooltip="{content: 'Eliminar Pago', placement: 'top'}">
                  <CIcon name="cil-trash" style="width: 0.75rem;height: 0.75rem;font-size: 0.75rem;"/>
                </CButton>
              </span>
            </span>
          </span>
        </td></template>
        <template #p5="{item}"><td class="w150 text-center" style="font-size: 12px;">
          <span v-if="item.payments[4].amount <= 100 || item.payments[4].amount == 99999">N/D<br /></span>
          <span v-if="item.payments[4].amount > 100 && item.payments[4].amount != 99999">
            <span v-if="item.payments[4].enrollment_payment == 0">
              <span v-if="item.payments[4].amount != 88888 && item.payments[4].amount != 99999">
                {{travel.type != 'national' ? 'USD' : ''}} {{item.payments[4].amount | currency}} {{travel.type != 'national' ? '' : 'Pesos'}}
                <br />
              </span>
              <span v-if="item.payments[4].enrollment_payment == 0">
                <br /><CButton color="danger" size="sm" @click="paymentModal(item, item.payments[4])" v-c-tooltip="{content: 'Registrar Pago', placement: 'top'}">PAGAR</CButton>
              </span>
            </span>
            <span v-if="item.payments[4].enrollment_payment != 0">
              {{item.payments[4].enrollment_payment.date | date}}<br />
              MXN {{item.payments[4].enrollment_payment.amount | currency}}
              <br />
              <span>
                <br />
                <CButton color="info" size="sm" @click="updatePaymentModal(item, item.payments[4])" v-c-tooltip="{content: 'Editar Pago', placement: 'top'}">PAGADO</CButton>
                <CButton v-if="item.payments[4].enrollment_payment.status != 'validated'" style="margin-left: 5px;" color="warning" size="sm" @click="validatePaymentModal(item, item.payments[4])" v-c-tooltip="{content: 'Validar Pago', placement: 'top'}">
                  <CIcon name="cil-warning" style="width: 0.6rem;height: 0.6rem;font-size: 0.6rem;"/>
                </CButton>
                <CButton v-if="item.payments[4].enrollment_payment.status == 'validated'" style="margin-left: 5px;" color="success" size="sm" @click="devalidatePaymentModal(item, item.payments[4])" v-c-tooltip="{content: 'Validar Pago', placement: 'top'}">
                  <CIcon name="cil-check" style="width: 0.6rem;height: 0.6rem;font-size: 0.6rem;"/>
                </CButton>
                <br /><br />
                <CButton v-if="item.payments[4].enrollment_payment.filename != 'N/A'" style="margin-left: 5px;" color="info" size="sm" @click="downloadReceipt(item, item.payments[4])" v-c-tooltip="{content: 'Comprobante de Pago', placement: 'top'}">
                  <CIcon name="cil-file" style="width: 0.75rem;height: 0.75rem;font-size: 0.75rem;"/>
                </CButton>
                <CButton style="margin-left: 5px;" color="danger" size="sm" @click="deletePaymentModal(item, item.payments[4])" v-c-tooltip="{content: 'Eliminar Pago', placement: 'top'}">
                  <CIcon name="cil-trash" style="width: 0.75rem;height: 0.75rem;font-size: 0.75rem;"/>
                </CButton>
              </span>
            </span>
          </span>
        </td></template>
        <template #observations="{item}">
        <td class="w300 text-left">
          <CButton style="float: right;" color="dark" size="sm" @click="editObservations(item)" v-c-tooltip="{content: 'Editar Observaciones', placement: 'top'}"><CIcon name="cil-pencil"/></CButton>
          {{item.payment_observations}}
        </td>
        </template>
        <template #total="{item}"><td class="w150 text-center">
          MXN {{item.total | currency}}
        </td></template>
        <template #other_payments="{item}"><td class="w150 text-center">
          MXN {{item.paid | currency}}
        </td></template>
        <template #balance="{item}"><td class="w150 text-center">
          MXN {{item.balance | currency}}
        </td></template>        
        <template #actions="{item}">
          <td class="table_actions w150">
            <CButton color="success" size="sm" @click="activeModal(item)" v-c-tooltip="{content: 'Agregar Pago', placement: 'top'}">Nuevo Pago</CButton>
          </td>
        </template>
      </CDataTable>
        <div class="totals" v-show="totals.length > 0">
          <div class="totals__list d-flex justify-content-center">
              <div class="totals__item" style="width: 200px;">
                  <div class="totals__amount">
                      <div class="totals__amount--with-taxes">{{!isNaN(totals["total"]) ? totals["total"] : 0| currency(2)}}</div>
                  </div>
                  <div class="totals__info d-flex justify-content-between">
                      <span style="margin: auto;">TOTAL</span>
                  </div>
              </div>
              <div class="totals__item" style="width: 200px;">
                  <div class="totals__amount">
                    <div class="totals__amount--with-taxes">{{!isNaN(totals["balance"]) ? totals["balance"] : 0| currency(2)}}</div>
                  </div>
                  <div class="totals__info d-flex justify-content-between">
                      <span style="margin: auto;">SALDO</span>
                  </div>
              </div>
          </div>
        </div>
    </CCardBody>

    <PaymentModal ref="paymentModal" @store="storeProcess" @update="updateProcess" @validate="validateProcess" @devalidate="devalidateProcess" @destroy="destroyProcess"></PaymentModal>
    <EditObservations ref="observationsPaymentModal" @observations="updatePaymentObservations"></EditObservations>
  </CCard>
</template>

<script>
import PaymentModal from './PaymentModal.vue'
import EditObservations from '../students/EditObservations.vue'
import ws from '../../../services/travels';
import payments from '../../../services/payments';
import store from '../../../store'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

export default {
  name: 'PaymentsTable',
  components: { PaymentModal, EditObservations },
  props: {
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'position', label: 'No.'},
          {key: 'name', label: 'Nombre del Alumno'},
          {key: 'p1', label: 'Pago 1'},
          {key: 'p2', label: 'Pago 2'},
          {key: 'p3', label: 'Pago 3'},
          {key: 'p4', label: 'Pago 4'},
          {key: 'p5', label: 'Pago 5'},
          {key: 'observations', label: 'Observaciones'}
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    id: 0,
    travel: {},
  },
  data() {
    return {
      toasts: [],
      students: [],
      payments: [],
      ps: [],
      totals: [{ balance: 0, total: 0 }],
    }
  },
  mounted: async function() {
    let _this = this;

    await setTimeout(async function(){
        let response = await ws.payments(_this.id);

        if(response.type == "success"){
          _this.payments = response.data;

          _this.ps = [];
          _this.ps.push({key: 'position', label: 'No.'});
          _this.ps.push({key: 'name', label: 'Nombre del Alumno'});

          let i = 0;
          _this.payments.forEach(payment => {
              i++;
              if(i <= 5){
                _this.ps.push({key: 'p'+i, label: 'Pago '+i});
              }
          });
          
          _this.ps.push({key: 'observations', label: 'Observaciones'});
          _this.ps.push({key: 'total', label: 'Total'});
          _this.ps.push({key: 'other_payments', label: 'Pagado'});
          _this.ps.push({key: 'balance', label: 'Saldo'});          
        }

        let response2 = await ws.studentsWithPayments(_this.id);

        if(response2.type == "success"){
          _this.students = response2.data;
          _this.totals["balance"] = response2.balance;
          _this.totals["total"] = response2.total;
        }
      }, 100);

      console.log(this.totals.length)
      
  },
  methods: {
    paymentModal (student, payment) {
      this.$refs.paymentModal.storeModal(student, payment, this.travel.exchange_rate);
    },
    updatePaymentModal (student, payment) {
      this.$refs.paymentModal.updateModal(student, payment, this.travel.exchange_rate);
    },
    validatePaymentModal (student, payment) {
      this.$refs.paymentModal.validateModal(student, payment, this.travel.exchange_rate);
    },
    devalidatePaymentModal (student, payment) {
      this.$refs.paymentModal.devalidateModal(student, payment, this.travel.exchange_rate);
    },
    editObservations (student) {
      this.$refs.observationsPaymentModal.show("Observaciones de los Pagos", student.id, student.name, student.payment_observations);
    },
    downloadReceipt (student, payment) {
      var link = document.createElement('a');
      link.href = payment.enrollment_payment.filename;
      link.download = payment.enrollment_payment.filename.substr(payment.enrollment_payment.filename.lastIndexOf('/') + 1);
      link.click();
    },
    deletePaymentModal (student, payment) {
      this.$refs.paymentModal.deleteModal(student, payment, this.travel.exchange_rate);
    },
    async storeProcess(payment, data) {
      if(data.date){
          let date = new moment(data.date);
          data.date = date.format("YYYY-MM-DD");
      }

      let response = await payments.store(data);
      
      if(response.type == "success"){
        payment.enrollment_payment = response.data;
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(payment, data) {
      if(data.date){
          let date = new moment(data.date);
          data.date = date.format("YYYY-MM-DD");
      }

      let response = await payments.update(data);

      if(response.type == "success"){
        payment.enrollment_payment = response.data;
      }

      this.showToast(response.type, response.message);
    },
    async updatePaymentObservations(id, observations) {
      let response = await ws.updatePaymentObservations({id: id, observations: observations});
      
      if(response.type == "success"){
        let response2 = await ws.studentsWithPayments(this.id);

        if(response2.type == "success"){
          this.students = response2.data;
        }
      }

      this.show_actives = true;
      this.show_waiting = true;

      this.showToast(response.type, response.message);
    },
    async validateProcess(payment, data) {
      if(data.date){
          let date = new moment(data.date);
          data.date = date.format("YYYY-MM-DD");
      }

      let response = await payments.validate(data);

      if(response.type == "success"){
        payment.enrollment_payment = response.data;
      }

      this.showToast(response.type, response.message);
    },
    async devalidateProcess(payment, data) {
      if(data.date){
          let date = new moment(data.date);
          data.date = date.format("YYYY-MM-DD");
      }

      let response = await payments.devalidate(data);

      if(response.type == "success"){
        payment.enrollment_payment = response.data;
      }

      this.showToast(response.type, response.message);
    },
    async destroyProcess(payment, data) {
      if(data.date){
          let date = new moment(data.date);
          data.date = date.format("YYYY-MM-DD");
      }

      let response = await payments.delete(data);

      if(response.type == "success"){
        payment.enrollment_payment = false;
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style>

.totals__item {
    text-align: center;
    margin: 1em 1em 2em;
    padding: 20px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.11);
}
.totals__amount {
    font-size: 1.5rem;
    color: #2f353b;
    font-weight: bold;
    border-bottom: 2px solid #5C9BD1;
}
.totals__info {
    font-size: 1.4rem;
    color: #AAB5BC;
    font-weight: 600;
    text-transform: uppercase;
}
.totals__amount--with-taxes {
    border-top: 2px solid #5C9BD1;
}
</style>
