<template>
    <CModal style="z-index: 100001" :title="title" color="dark" :show.sync="observationsModal">   
        <p style="font-weight: bold;">¿Estás seguro que deseas agrega/editar las observaciones?</p>
        Observaciones:
        <CTextarea v-model="observations" style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);"
          rows="3"
          text="Must be 8-20 words long."
        ></CTextarea>
        <template #header>
          <h5 class="modal-title">{{title}}</h5>
          <CButtonClose @click="observationsModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="observationsModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="update" color="danger">Guardar</CButton>
        </template>
    </CModal>
</template>

<script>
import store from '../../../store'

export default {
  name: 'EditObservations',
  props: {

  },
  data () {
    return {
      observationsModal: false,
      title: "",
      id: 0,
      name: 'Registro',
      observations: ''
    }
  },
  methods: {
    show (title, id, name, observations) {
      this.observationsModal = true;
      this.title = title;
      this.id = id;
      this.name = name;
      this.observations = observations;

      this.loaded();
    },
    update () {
      this.observationsModal = false;
      this.$emit("observations", this.id, this.observations);
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>