<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> Preguntas del Formulario [ <a :href="form.link" target="_blank">{{form.link}}</a> ]
        <div class="card-header-actions">
          <CButton color="danger" size="sm" @click="deleteForm()">Eliminar Formulario</CButton>
          <CButton color="success" size="sm" @click="showQuestions()">Respuestas</CButton>
          <CButton color="info" size="sm" @click="questionModal()">Nueva Pregunta</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="questions"
        :fields="fields"
        :items-per-page="30"
        :dark="dark"
        pagination
        :tableFilter="{label: 'Buscar: ', placeholder: ' '}"
        :itemsPerPageSelect="{label: 'Preguntas por página: ', values: [10,20,30,50,100]}"
      >
        <template #position="{item}"><td class="text-center w100">{{item.position}}</td></template>
        <template #type="{item}"><td class="text-center w100">{{item.type == 'text' ? 'Texto' : (item.type == 'options' ? 'Opciones' : (item.type == 'date' ? 'Fecha' : (item.type == 'file' ? 'Archivo' : (item.type == 'number' ? 'Numérico' : (item.type == 'content' ? 'Contenido' : 'N/A')))))}}</td></template>
        <template #question="{item}">
          <td class="text-left">
            <div v-if="item.type != 'content'"><strong>{{item.question}} <span style="color: red;" v-if="item.required">*</span></strong><br />{{item.description}}</div>
            <pre v-if="item.type == 'content'" style='width: 100%; font-size: 16px; font-family: "Open Sans"; white-space: pre-wrap; font-size: 14px; margin: 0px; padding: 0px; text-align: justify'>
              <div style=" margin: 0px; padding: 0px;" v-html="item.description"></div></pre>
          </td>
        </template>
        <template #options="{item}"><td class="text-left w400" style="vertical-align: middle;"><span v-if="item.optionsLabels"><ul style="margin: 0px;"><li v-for="option in item.optionsLabels">{{option}}</li></ul></span></td></template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton color="warning" size="sm" @click="updateQuestionModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteQuestionModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
      <div class="fullscreen" v-if="show_answers">
        <div style="width: 100%: min-height: 800px;">
          <div style="float: right;position: fixed;z-index: 100000;margin: 0;top: 0;right: 0;">
            <CButton color="info" @click="hideQuestions()" size="sm" style="margin-top: 10px;margin-right: 25px;">Cerrar</CButton><br /><br /><br /><br />
          </div>
            <CDataTable
              style="overflow: auto;"
              :hover="hover"
              :striped="striped"
              :border="border"
              :small="small"
              :fixed="fixed"
              :items="form_items"
              :fields="form_fields"
              :items-per-page="1000"
              :itemsPerPageSelect="false"
              :dark="dark"
              pagination
              sorter
              :tableFilter="{label: 'Buscar: ', placeholder: ' '}"
              class="responses"
              :download="true"
              @downloading="downloading"

            >
              <template #[field.key]="{item, index}" v-for="field in form_fields">
                <td class="w400 text-left" v-if="field.key == 'observations'">
                  <CButton style="float: right;" color="dark" size="sm" @click="editObservations(item)" v-c-tooltip="{content: 'Agregar Observaciones', placement: 'top'}"><CIcon name="cil-pencil"/></CButton>
                  <p style="vertical-align: middle; width: 330px;" align="justify">
                    {{item.observations ? item.observations : ''}}
                  </p>
                </td>
                <td class="text-left" style="min-width: 300px; font-size: 14px;" v-if="item['type_'+field.key] == 'text'" @click="editTextCell(item, field)">
                  <span v-if="!item['edit_'+field.key]">{{item[field.key]}}</span>
                  <CButton style="float: right; height: 32px; margin-top: 1px;" v-if="item['edit_'+field.key]" color="info" size="sm" @click="updateTextCell(item, field, item['question_'+field.key])" v-c-tooltip="{content: 'Guardar', placement: 'top'}"><CIcon name="cil-save"/></CButton>
                  <CInput
                    type="text"
                    v-show="item['edit_'+field.key]"
                    v-model="item['question_'+field.key]"
                    label=""
                    :lazy="false"
                    placeholder=""
                    style="width: 270px;"
                    /> 
                </td>
                <td class="text-left" style="min-width: 200px; font-size: 14px;" v-if="item['type_'+field.key] == 'date'" @click="editDateCell(item, field)">
                  <span v-if="!item['edit_'+field.key]">{{item["question_"+field.key] | date}}</span>
                  <CButton style="float: right; height: 32px; margin-top: 1px;" v-if="item['edit_'+field.key]" color="info" size="sm" @click="updateDateCell(item, field, item['question_'+field.key])" v-c-tooltip="{content: 'Guardar', placement: 'top'}"><CIcon name="cil-save"/></CButton>
                  <v-date-picker
                      v-show="item['edit_'+field.key]"
                      :id="'edit_'+field.key"
                      :name="'edit_'+field.key"
                      class="date-picker"
                      v-model="item['question_'+field.key]"
                      locale="es-MX" size="sm"
                      style="width: 270px;"
                  />
                </td> 
                <td class="text-left" style="min-width: 200px; font-size: 14px;" v-if="item['type_'+field.key] == 'number'" @click="editTextCell(item, field)">
                  <span v-if="!item['edit_'+field.key]">{{item["question_"+field.key]}}</span>
                  <CButton style="float: right; height: 32px; margin-top: 1px;" v-if="item['edit_'+field.key]" color="info" size="sm" @click="updateTextCell(item, field, item['question_'+field.key])" v-c-tooltip="{content: 'Guardar', placement: 'top'}"><CIcon name="cil-save"/></CButton>
                  <CInput
                    type="number"
                    v-show="item['edit_'+field.key]"
                    v-model="item['question_'+field.key]"
                    label=""
                    :lazy="false"
                    placeholder=""
                    style="width: 270px;"
                  /> 
                </td>
                <td class="text-left" style="min-width: 300px; font-size: 14px;" v-if="item['type_'+field.key] == 'options'" @click="editOptionsCell(item, field)">
                  <span v-if="!item['edit_'+field.key]">{{item["question_"+field.key]}}</span>
                  <CButton style="float: right; height: 32px; margin-top: 1px;" v-if="item['edit_'+field.key]" color="info" size="sm" @click="updateOptionsCell(item, field, item['question_'+field.key])" v-c-tooltip="{content: 'Guardar', placement: 'top'}"><CIcon name="cil-save"/></CButton>
                  <CSelect
                    v-if="item['edit_'+field.key]"
                    :options="item['options_'+field.key]"
                    style="width: 270px;"
                    :value="value"
                    @change="changeOptionsCell($event, item, field)"
                  />
                </td>
                <td class="text-center" style="min-width: 300px; font-size: 14px;" v-if="item['type_'+field.key] == 'file'">
                  <CButton v-if="item[field.key]" color="success" size="sm" @click="downloadFile(item['question_'+field.key])" style="margin-right: 10px; !important;">Descargar Archivo</CButton> 
                  <CButton color="info" size="sm" @click="uploadModal(item, field)">Cargar Archivo</CButton>
                </td>
              </template>
            </CDataTable>
        </div>
      </div>
    </CCardBody>
    <DynamicQuestionModal ref="dynamicQuestionFormModal" @store="storeProcess" @update="updateProcess"></DynamicQuestionModal>
    <DeleteModal ref="deleteQuestionFormModal" @delete="deleteProcess"></DeleteModal>
    <FileUpload ref="uploadModal" @upload="upload"></FileUpload>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import DynamicQuestionModal from './DynamicQuestionModal.vue'
import DeleteModal from '../../../components/global/DeleteModal.vue'
import FileUpload from '../students/FileUpload.vue'

import ws from '../../../services/travels';
import store from '../../../store'

import { DatePicker } from 'v-calendar'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

export default {
  name: 'DynamicFormQuestionsTable',
  components: { DynamicQuestionModal, DeleteModal, 'v-date-picker': DatePicker, FileUpload },
  props: {
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'position', label: 'No.'},
          {key: 'type', label: 'Tipo'},
          {key: 'question', label: 'Pregunta'},
          {key: 'options', label: 'Opciones'},
          {key: 'actions', label: 'Acciones'}
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    id: 0,
    travel: {},
    form: {},
  },
  data() {
    return {
      toasts: [],
      title: 'Preguntas',
      show_answers: false,
      questions: [],
      ps: [],
      form_fields: [],
      form_items: []
    }
  },
  mounted: async function() {
    let _this = this;

    setTimeout(async function(){
      let response1 = await ws.formQuestions(_this.form.id);

      if(response1.type == "success"){
          _this.questions = response1.data;
      }

      let response2 = await ws.formFieldQuestions(_this.form.id);

      if(response2.type == "success"){
          _this.form_fields = response2.data;
      }

      let response3 = await ws.formAnswers(_this.form.id);

      if(response3.type == "success"){
        _this.form_items = response3.data;
      }
    }, 100);
  },
  methods: {
    async showQuestions () {
      this.show_answers = true;
    },
    async hideQuestions () {
      this.show_answers = false;
    },
    questionModal () {
      this.travel.form = this.form;
      this.$refs.dynamicQuestionFormModal.storeModal(this.travel, 0, this.form.id);
    }, 
    updateQuestionModal (item) {
      this.$refs.dynamicQuestionFormModal.updateModal(item, this.travel.form.id);
    },
    deleteQuestionModal (item) {
      this.$refs.deleteQuestionFormModal.show("Eliminar Pregunta", item.id, item.name);
    },
    async storeProcess(data) { 
      data.travel_form_id = this.travel.form.id;

      let response = await ws.storeQuestion(data);
      
      if(response.type == "success"){
        this.questions = response.data;
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      data.travel_form_id = this.travel.form.id;

      let response = await ws.updateQuestion(data);

      if(response.type == "success"){
        this.questions = response.data;
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(data){
      this.loading();
      let response = await ws.deleteQuestion(data);

      if(response.type == "success"){
        this.questions = response.data;
      }

      this.showToast(response.type, response.message);

      this.loaded();
    },
    async editTextCell (item, field) {
      if(item["edit_"+field.key]){
        return;
      }
      
      let _this = this;
      this.form_items.forEach(s => {
        this.form_fields.forEach(f => {
          if(s["edit_"+f.key] && (item.id != s.id || field.key != f.key)){
            if(f.type == "text") _this.updateTextCell(s, f, s['question_'+f.key]);
            if(f.type == "date") _this.updateDateCell(s, f, s['question_'+f.key]);
            if(f.type == "options") _this.updateOptionsCell(s, f, s['question_'+f.key]);
          }
          s["edit_"+f.key] = false;    
        });
      });

      item["edit_"+field.key] = true;
    },
    async updateTextCell (item, field, value) {
      const formData = new FormData();

      formData.append("travel_id", this.travel.id);
      formData.append("travel_form_id", this.form.id);
      formData.append("response_id", item.id);
      formData.append("question_id", field.key);
      formData.append("value", value);

      let response = await ws.storeFormAnswer(formData);

      if(response.type == "success"){
        setTimeout(async function(){
          let data = response.data;
          item["edit_"+field.key] = false;
          item[data.code] = data.value;
          item[data.question] = data.value;
        }, 10);
      }

      this.showToast(response.type, response.message);
    },
    async editDateCell (item, field) {
      if(item["edit_"+field.key]){
        return;
      }

      let _this = this;

      this.form_items.forEach(s => {
        this.form_fields.forEach(f => {
          if(s["edit_"+f.key] && (item.id != s.id || field.key != f.key)){
            if(f.type == "text") _this.updateTextCell(s, f, s['question_'+f.key]);
            if(f.type == "date") _this.updateDateCell(s, f, s['question_'+f.key]);
            if(f.type == "options") _this.updateOptionsCell(s, f, s['question_'+f.key]);
          }
          s["edit_"+f.key] = false;    
        });
      });

      item["edit_"+field.key] = true;

      let date = moment(item[field.key], "YYYY-MM-DD");

      item['question_'+field.key] = date.toDate();
    },
    async updateDateCell (item, field, value) {
      const formData = new FormData();

      formData.append("travel_id", this.travel.id);
      formData.append("travel_form_id", this.form.id);
      formData.append("response_id", item.id);
      formData.append("question_id", field.key);

      let date = new moment(value);
      formData.append("value", date.format("YYYY-MM-DD"));

      let response = await ws.storeFormAnswer(formData);

      if(response.type == "success"){
        setTimeout(async function(){
          let data = response.data;
          item["edit_"+field.key] = false;
          item[data.code] = data.value;
          item[data.question] = data.value;
        }, 10);
      }

      this.showToast(response.type, response.message);
    },
    async editOptionsCell (item, field) {
      if(item["edit_"+field.key]){
        return;
      }
      
      let _this = this;

      _this.form_items.forEach(s => {
        _this.form_fields.forEach(f => {
          if(s["edit_"+f.key] && (item.id != s.id || field.key != f.key)){
            if(f.type == "text") _this.updateTextCell(s, f, s['question_'+f.key]);
            if(f.type == "date") _this.updateDateCell(s, f, s['question_'+f.key]);
            if(f.type == "options") _this.updateOptionsCell(s, f, s['question_'+f.key]);
          }

          s["edit_"+f.key] = false;    
        });
      });

      let options = item["options_"+field.key];

      item['options_'+field.key] = options;

      setTimeout(async function(){
        _this.value = item['question_'+field.key];
        item["edit_"+field.key] = true;
      }, 10);
    },
    changeOptionsCell(event, item, field){
      let _this = this;
      setTimeout(async function(){
          _this.value = event.target.value;
          item['question_'+field.key] = event.target.value;
      }, 10);
    },
    async updateOptionsCell (item, field, value) {
      const formData = new FormData();

      formData.append("travel_id", this.travel.id);
      formData.append("travel_form_id", this.form.id);
      formData.append("response_id", item.id);
      formData.append("question_id", field.key);
      formData.append("value", value);

      let response = await ws.storeFormAnswer(formData);

      this.value = '';

      if(response.type == "success"){
        setTimeout(async function(){
          let data = response.data;
          item["edit_"+field.key] = false;
          item[data.code] = data.value;
          item[data.question] = data.value;
        }, 100);
      }

      this.showToast(response.type, response.message);
    },
    async downloading (items) {
      let response_ids = [];
      items.forEach(item => {
        response_ids.push(item.id);
      });

      let response = await ws.downloadFormFiltered(this.travel.id, this.form.id, response_ids);

      if(response.type == "success"){
        let link = response.data;
      }
    },
    uploadModal (item, field) {
      item.response_id = item.id;
      this.$refs.uploadModal.show("Cargar Archivo", item, field);
    },
    upload (item, field, data) {
      item["question_"+field.key] = data;
      item[field.key] = data;
    },
    downloadFile (url) {
      var link = document.createElement('a');
      link.href = url;
      link.click();
    },
    deleteForm (item) {
      this.$refs.deleteModal.show("Eliminar Formulario", this.form.id, this.form.presentation_link+", sus preguntas y sus respuestas");
    },
    async deleteProcess(id) {
      let response = await ws.deleteForm({id: id});
      
      if(response.type == "success"){
        window.location.href = "/#/travels/"+this.travel.id+"/show";
        window.location.reload();
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
